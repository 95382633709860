import React, { useState, useRef, useEffect } from "react";
import SidebarNavLink from "./SidebarNavLink/SidebarNavLink";
import { Mobile } from "../Responsive";
import { SignedIn, SignedOut } from "../UserState/UserState";
import { withRouter } from "react-router-dom";
import { signOut } from "../../Firebase/UserUtils";
import Searchbar from "../Searchbar/Searchbar";
import "./BottomNav.scss";

import SwitchIcon from "../../assets/switch.svg";
import HomeIcon from "../../assets/home.svg";
import MoviesIcon from "../../assets/movie.svg";
import TvIcon from "../../assets/tv.svg";
import SettingsIcon from "../../assets/settings.svg";
import SignalIcon from "../../assets/signal.svg";
import WatchingIcon from "../../assets/watching.svg";
import PlannedIcon from "../../assets/planned.svg";
import CompletedIcon from "../../assets/completed.svg";
import DroppedIcon from "../../assets/dropped.svg";
import UserIcon from "../../assets/user.svg";
import { FaSearch, FaTimes } from 'react-icons/fa';

function BottomNav({ history, searchbarValue, setSearchbarValue, searchHandler }) {
  const [showMore, setShowMore] = useState(false);
  const [searchIsVisible, setSearchIsVisible] = useState(false);
  const searchbarContainerRef = useRef(null);  // Ref para o container da barra de pesquisa

  const toggleShowMore = () => {
    setShowMore(!showMore);
    if (searchIsVisible) setSearchIsVisible(false); // Fecha a pesquisa ao abrir "More"
  };

  const handleLogout = () => {
    signOut().then(() => {
      history.push('/login');
      setShowMore(false);
    });
  };

  const handleItemClick = () => {
    setShowMore(false);
    if (searchIsVisible) setSearchIsVisible(false); // Fecha a pesquisa ao clicar em outro item do menu
  };

  const showSearch = () => {
    setSearchIsVisible(true);
    setShowMore(false); // Fecha o "More" se o search for aberto
  };

  const hideSearch = () => {
    setSearchIsVisible(false);
  };

  const handleSearchSubmit = (event) => {
    if (event.key === 'Enter') {
      searchHandler(); // Executa a pesquisa
      setSearchIsVisible(false); // Fecha a barra de pesquisa após a busca
    }
  };

  // Fechar automaticamente a barra de pesquisa se outra opção do menu for clicada
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchbarContainerRef.current &&
        !searchbarContainerRef.current.contains(event.target) &&
        searchIsVisible
      ) {
        setSearchIsVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchIsVisible]);

  return (
    <Mobile>
      <div className="bottom-nav">
        <SidebarNavLink exact to="/" activeClassName="active" onClick={handleItemClick}>
          <SignalIcon fill="currentColor" />
          <span className="nav-text">Explore</span>
        </SidebarNavLink>
        <SidebarNavLink to="/movies" activeClassName="active" onClick={handleItemClick}>
          <MoviesIcon fill="currentColor" />
          <span className="nav-text">Movies</span>
        </SidebarNavLink>
        <SidebarNavLink to="/shows" activeClassName="active" onClick={handleItemClick}>
          <TvIcon fill="currentColor" />
          <span className="nav-text">TV Shows</span>
        </SidebarNavLink>

        {/* Searchbar Button with Icon and Text */}
        <button id="search-show" onClick={showSearch}>
          <FaSearch className="search-icon" />
          <span className="nav-text">Search</span>
        </button>

        {searchIsVisible && (
          <div className="searchbar-container" ref={searchbarContainerRef}>
            <Searchbar
              value={searchbarValue}
              search={searchHandler}  // Passando a função de pesquisa corretamente
              setSearchbarValue={setSearchbarValue}
              onKeyDown={handleSearchSubmit}
            />
            <button id="search-hide" onClick={hideSearch}>
              <FaTimes className="close-icon" />
            </button>
          </div>
        )}

        <SidebarNavLink to="/settings" activeClassName="active" onClick={handleItemClick}>
          <SettingsIcon fill="currentColor" />
          <span className="nav-text">Settings</span>
        </SidebarNavLink>

        <div className="more-menu">
          <button className="more-button" onClick={toggleShowMore}>
            <SwitchIcon fill="currentColor" />
            <span className="nav-text">More</span>
          </button>
          <div className={`additional-menu ${showMore ? "show" : ""}`}>
            <SignedIn>
              {user => (
                <>
                  <SidebarNavLink to={`/user/${user.uid}/watching/`} onClick={handleItemClick}>
                    <WatchingIcon fill="currentColor" />
                    <span className="nav-text">Watching</span>
                  </SidebarNavLink>
                  <SidebarNavLink to={`/user/${user.uid}/plan_to_watch/`} onClick={handleItemClick}>
                    <PlannedIcon fill="currentColor" />
                    <span className="nav-text">Planned</span>
                  </SidebarNavLink>
                  <SidebarNavLink to={`/user/${user.uid}/completed/`} onClick={handleItemClick}>
                    <CompletedIcon fill="currentColor" />
                    <span className="nav-text">Completed</span>
                  </SidebarNavLink>
                  <SidebarNavLink to={`/user/${user.uid}/dropped/`} onClick={handleItemClick}>
                    <DroppedIcon fill="currentColor" />
                    <span className="nav-text">Dropped</span>
                  </SidebarNavLink>
                  <button className="more-button" onClick={handleLogout}>
                    <UserIcon fill="currentColor" />
                    <span className="nav-text">Logout</span>
                  </button>
                </>
              )}
            </SignedIn>
            <SignedOut>
              <>
                <SidebarNavLink to="/login" onClick={handleItemClick}>
                  <UserIcon fill="currentColor" />
                  <span className="nav-text">Login</span>
                </SidebarNavLink>
                <div className="notif">Log in to view your Watchlist</div>
              </>
            </SignedOut>
          </div>
        </div>
      </div>
    </Mobile>
  );
}

export default withRouter(BottomNav);
